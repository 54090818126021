import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent, IconButton, Tooltip } from "@material-ui/core";
import { CLOSE_NEW_REDEEM_DIALOG } from "../../store/redeem/types";
import cancelButton from "../../assets/images/cancel.png";
import { createAgencyRedeem } from "../../store/redeem/action";
// import createAgencyRedeem from

const AgencyRedeemCreate = () => {
  const dispatch = useDispatch();
  const { dialog, dialogData, setting } = useSelector((state) => state.redeem);
  const { seller } = useSelector((state) => state.admin);

  const [coin, setCoin] = useState("");
  const [description, setDescription] = useState("");
  const [paymentGateway, setPaymentGateway] = useState("");
  const [errors, setErrors] = useState({});

  console.log("dialogData", dialogData);

  useEffect(() => {
    if (dialogData) {
      setDescription(dialogData.description);
      setPaymentGateway(dialogData.paymentGateway);
    }
  }, [dialogData]);

  const closePopup = () => {
    dispatch({ type: CLOSE_NEW_REDEEM_DIALOG });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let error = {};

    if (!description) error.description = "Description is required!";
    if (!coin) error.coin = "Coin is required!";
    if (coin < setting?.minRcoinForCashOutAgency)
      error.coin = `Minimum cashout amount is ${setting?.minRcoinForCashOutAgency}`;

    if (Object.keys(error).length > 0) {
      setErrors(error);
      return;
    }

    const data = { agencyId: dialogData?._id, description, coin };
    console.log("Submitting Data:", data);

    dispatch(createAgencyRedeem(data));

    // Add your submit action here
    closePopup();
  };

  return (
    <Dialog open={dialog} onClose={closePopup} fullWidth maxWidth="xs">
      <div className="dialog-header d-flex justify-content-between py-3">
        <p
          className="text-center mb-0 col-8"
          style={{ color: "#fff", fontSize: "17px" }}
        >
          Agency Redeem
        </p>
          {/* <IconButton onClick={closePopup} style={{ color: "#fff" }}>
            <Tooltip title="Close">
              <img src={cancelButton} width={22} height={22} alt="cancel" />
            </Tooltip>
          </IconButton> */}
      </div>

      <DialogContent>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="text-white mb-2">Description</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="form-control"
              style={{
                backgroundColor: "#422F4F",
                borderColor: "#4a2f59",
                padding: "10px",
                borderRadius: "4px",
                width: "100%",
                color: "#fff",
              }}
            />
            {errors.description && (
              <p style={{ color: "red" }}>{errors.description}</p>
            )}
          </div>

          <div className="form-group mt-3">
            <label className="text-white mb-2">Coin</label>
            <input
              type="number"
              value={coin}
              onChange={(e) => setCoin(e.target.value)}
              className="form-control"
              style={{
                backgroundColor: "#422F4F",
                borderColor: "#4a2f59",
                padding: "10px",
                borderRadius: "4px",
                width: "100%",
                color: "#fff",
              }}
            />
            {errors.coin && <p style={{ color: "red" }}>{errors.coin}</p>}
          </div>
              <div className="pb-3 d-flex justify-content-end">

          <button type="submit" className="btn btn-primary mt-3">
            Submit
          </button>

          <button type="submit" className="btn btn-danger mt-3" onClick={closePopup} style={{
            marginLeft : "10px"
          }}>
            Close
          </button>
              </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AgencyRedeemCreate;
