export const GET_HISTORY = "GET_HISTORY";

export const COIN_DATA_CREATE = " COIN_DATA_CREATE ";

// open dialogue

export const OPEN_COIN_SELLER_DIALOGUE = "OPEN_COIN_SELLER_DIALOGUE";

// open dialogue

export const CLOSE_COIN_SELLER_DIALOGUE = "CLOSE_COIN_SELLER_DIALOGUE";

// history table 

export const HISTORY_TABLE = "HISTORY_TABLE";
