import React, { Suspense, useEffect } from "react";

// routing
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
//redux
import { useDispatch, useSelector } from "react-redux";
//types
import { SET_ADMIN } from "./store/admin/types";

import Admin from "./pages/Admin";
import TotalIncome from "./pages/TotalIncome";
import HistoryList from "./pages/HistoryList";
import Creators from "./pages/Creators";
import HostHistory from "./pages/HostHistory";
import HostRequest from "./pages/hostRequest/HostRequest";
import { AgencyRedeem } from "./pages/AgencyRedeem";
import { TodayEarning } from "./pages/TodayEarning";

function App() {
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.admin);
  const token = localStorage.getItem("TOKEN");
  const key = localStorage.getItem("KEY");

  const params = new URLSearchParams(window.location.search);

  const agencyId = params.get("id") || localStorage.getItem("agencyId");


  localStorage.setItem("agencyId", agencyId);

  useEffect(() => {
    if (!token && !key) return;
    dispatch({ type: SET_ADMIN, payload: token });
  }, [token, key, dispatch]);

  return (
    <div className="App">
      <Suspense fallback={""}>
        <BrowserRouter>
          <Switch>
            <Route path={"/agencypanel/homePage"} component={Admin} />
            {/* <Route path={"/agencypanel/Income"} component={TotalIncome} /> */}
            <Route path={"/agencypanel/creators"} component={Creators} />
            <Route path={"/agencypanel/todayEarning"} component={TodayEarning} />

            <Route
              path={"/agencypanel/creatorRequest"}
              component={HostRequest}
            />
             <Route
              path={"/agencypanel/agencyredeem"}
              component={AgencyRedeem}
            />
            <Route path={"/agencypanel/hosthistory"} component={HostHistory} />
            <Redirect from="/" to="/agencypanel/homePage" />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
